<template>
  <div class="mt-5 pb-5">
    <div v-if="!emailStatus">
      <v-flex xs12 class="text-left">
        <v-form ref="frmMain" v-model="valid" lazy-validation>
          <TextField :rules="[rules.required, rules.EmailCheck]" prepend-icon="mdi-account" v-model="email" label="Email" />
        </v-form>
      </v-flex>
      <div class="actions text-center">
        <v-btn @click="sendLink()" :loading="isLoading" class="primary_btn">Send me a magic link</v-btn>
      </div>
    </div>
    <div v-else>
      <h3 class="headline text-center mb-5">
        We have sent your login link to
        <strong>{{ email }}</strong>
        . Please follow the link in the email to login to your account
      </h3>
      <span v-if="!isLoading" class="error-text">
        Having trouble receiving this email?
        <a href="#" @click.prevent="reportLoginIssue()">click here to contact admin</a>
      </span>
      <span v-if="isLoading" class="info-text">Sending login issue report ...</span>
    </div>
  </div>
</template>
<script lang="ts">
  import rules from "shared-components/src/utils/validations"
  import router from "@/router"
  import { defineComponent } from "vue"
  import UserService from "../../services/UserService"
  import { Paths, ProjectMessages } from "shared-components/src/definitions/constants"
  import store from "@/store"
  import { ClientApi, ClientAuthApi } from "shared-components/src/services/openApi"
  var clientAuthApi = new ClientAuthApi()

  export default defineComponent({
    mounted() {},
    data: () => ({
      valid: false,
      rules,
      emailStatus: false,
      email: "",
      isLoading: false,
    }),
    methods: {
      showError(error: string) {
        store.dispatch("showErrorMessage", error)
      },
      gotoDashboard() {
        router.push(Paths.Client.Route)
      },
      async reportLoginIssue() {
        this.isLoading = true
        try {
          var reportLoginIssueResult = await clientAuthApi.sendClientLoginIssueReport(this.email)
          if (reportLoginIssueResult.data) {
            store.dispatch("showSuccessMessage", "An email was sent to admin successfully")
          }
        } finally {
          this.isLoading = false
        }
      },
      async sendLink() {
        const isValid = await (this.$refs.frmMain as any).validate()
        if (isValid.valid) {
          this.isLoading = true
          this.$emit("success")
          UserService.sendAuthenticationLink(this.email)
            .then((result) => {
              if (result.status) {
                this.emailStatus = true
                store.dispatch("setSendLoginStatus", true)
              } else {
                this.showError(ProjectMessages.WrongOrInactiveEmail)
              }
              this.isLoading = false
            })
            .catch((err) => {
              this.showError(ProjectMessages.LostConnection)
              this.isLoading = false
            })
        }
      },
    },
  })
</script>
