<template>
  <div class="header-title">
    <v-app-bar-nav-icon class="nav-icon">
      <i
        class="icon v-icon"
        :class="{
          notranslate: true,
          mdi: true,
          dark: false,
          [icon]: true,
        }"></i>
    </v-app-bar-nav-icon>
    <span class="f-sub-heading">{{ title }}</span>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from "vue"
  export default defineComponent({
    data() {
      return {
        icon: "",
        title: "",
      }
    },
    created() {
      this.icon = this.$route.meta.icon as string
      this.title = this.$route.meta.title as string
    },
    watch: {
      $route() {
        this.icon = this.$route.meta.icon as string
        this.title = this.$route.meta.title as string
      },
    },
  })
</script>
<style scoped lang="scss">
  @import "node_modules/shared-components/assets/sizes.scss";

  .header-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .icon {
      color: $c_bittersweet_2;
      font-size: $size-m;
    }
  }
</style>
