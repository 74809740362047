<template>
  <v-tooltip location="bottom" :disabled="alerts.length == 0" content-class="alert-container" :eager="false">
    <template v-slot:activator="{ props }">
      <div :class="[alerts.length == 0 ? 'bg-grey-lighten-3' : type == 'Risk' ? 'bg-error' : type == 'Warning' ? 'bg-warning' : 'bg-info']" v-bind="props" class="alert-item">
        <span class="alert-count">{{ alerts.length }}</span>
        <span>
          {{ type }}
        </span>
      </div>
    </template>
    <div class="alert-content">
      <v-alert
        :text="item"
        v-for="(item, index) in alerts"
        :key="index"
        :type="type == 'Risk' ? 'error' : type == 'Warning' ? 'warning' : 'info'"
        density="compact"
        class="alert-message" />
    </div>
  </v-tooltip>
</template>
<script lang="ts">
  import { defineComponent, PropType } from "vue"
  import { TeammemberWithVmDetails, TeammemberWithVmDetailsCommitmentAdminApprovalEnum } from "shared-components/src/services/openApi/api"
  export default defineComponent({
    props: {
      tooltip: {
        type: String,
      },
      icon: {
        type: String,
      },
      type: {
        type: String as PropType<"Info" | "Warning" | "Risk">,
      },
      commitment: {
        type: Object as PropType<TeammemberWithVmDetails>,
      },
    },
    data() {
      return {
        alerts: [] as string[],
        approvalStatus: TeammemberWithVmDetailsCommitmentAdminApprovalEnum,
      }
    },
    computed: {},
    methods: {
      generateAlerts() {
        this.alerts = []
        if (this.type == "Risk") {
          if (this.commitment?.NearToExpire) {
            this.alerts.push("This Commitment is near to expire")
          }
        }

        if (this.type == "Info") {
          if (this.commitment?.HaveUpcomingCommitment) {
            this.alerts.push("This Commitment already have a upcoming commitment")
          }
        }

        if (this.type == "Warning") {
          if (!this.isStatusApproved(this.commitment?.CommitmentAdminApproval)) {
            this.alerts.push("This commitment is awaiting Admin approval.")
          }
          if (!this.isStatusApproved(this.commitment?.CommitmentCustomerApproval)) {
            this.alerts.push("This commitment is awaiting Customer approval.")
          }
          if (!this.isStatusApproved(this.commitment?.CommitmentTeammemberApproval)) {
            this.alerts.push("This commitment is awaiting Team Member approval.")
          }
        }
      },
      isStatusApproved(status: TeammemberWithVmDetailsCommitmentAdminApprovalEnum | undefined) {
        return status == this.approvalStatus.ApprovedExplicit || status == this.approvalStatus.ApprovedInherited || status == undefined || status == null
      },
    },
    async created() {},
    watch: {
      commitment: {
        handler() {
          this.generateAlerts()
        },
        deep: true,
        immediate: true,
      },
    },
  })
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/style.scss";

  :deep(.alert-container) {
    background: transparent !important;
    padding: 0 !important;
  }
  .alert-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .alert-item {
    display: flex;
    gap: 5px;
    font-size: 14px;
    padding: 5px;
    @extend .b-r-5;
    height: min-content;

    justify-content: center;
    .alert-count {
      border-radius: 20px;
      background: $c_white;
      color: $c_black;
      width: 20px;
      height: 20px;
      text-align: center;
    }
  }
</style>
