<template>
  <div class="header-menu-container">
    <img src="@/assets/images/code-clan-logo.svg" class="header-menu-logo" />
    <div class="header-menu">
      <!-- <router-link to="" class="header-menu-item">
      <div class="header-menu-item-content">
        <v-icon>mdi-home</v-icon>
        <span>Dashboard</span>
      </div>
    </router-link> -->
      <router-link to="/client/management" v-if="isPortfolioManager || isClient" class="header-menu-item">
        <div class="header-menu-item-content">
          <img src="@/assets/images/icon/management.svg" />
          <span>Management</span>
        </div>
      </router-link>
      <router-link to="/client/cost" v-if="isPortfolioManager" class="header-menu-item">
        <div class="header-menu-item-content">
          <img src="@/assets/images/icon/cost.svg" />
          <span>Cost</span>
        </div>
      </router-link>
      <v-menu v-model="trainingMenu" location="bottom" transition="scale-transition">
        <template v-slot:activator="{ props }">
          <div class="header-menu-item">
            <div class="header-menu-item-content" v-bind="props">
              <img src="@/assets/images/icon/training.svg" />
              <span>Training</span>
            </div>
          </div>
        </template>
        <v-list>
          <v-list-item to="/client/training/roadMap" title="Training RoadMaps" prepend-icon="mdi-account-school" value="RoadMap" />
          <v-list-item to="/client/training/courses" title="Courses" prepend-icon="mdi-school" value="Courses" />
          <v-list-item to="/client/training/assessment" title="Assessments" prepend-icon="mdi-book-clock" value="Assessment" />
        </v-list>
      </v-menu>

      <router-link to="/client/planning" v-if="isPortfolioManager || isClient" class="header-menu-item">
        <div class="header-menu-item-content">
          <img src="@/assets/images/icon/planning.svg" />
          <span>Planning</span>
        </div>
      </router-link>
      <router-link to="/client/productivity" v-if="isProjectLead || isPortfolioManager" class="header-menu-item">
        <div class="header-menu-item-content">
          <img src="@/assets/images/icon/productivity.svg" />
          <span>Productivity</span>
        </div>
      </router-link>
      <router-link to="" class="header-menu-item">
        <div class="header-menu-item-content">
          <img src="@/assets/images/icon/security.svg" />
          <span>Security</span>
        </div>
      </router-link>
      <v-menu v-model="resourceMenu" location="bottom" transition="scale-transition" v-if="userIsClient || userIsPortfolioManager">
        <template v-slot:activator="{ props }">
          <div class="header-menu-item">
            <div class="header-menu-item-content" v-bind="props">
              <img src="@/assets/images/icon/quick-actions.svg" />
              <span>Resource</span>
            </div>
          </div>
        </template>
        <v-list>
          <v-list-item to="/client/virtualDesktop">Smart Workbench</v-list-item>
          <v-list-item @click="GoToProject">Project</v-list-item>
          <v-list-item to="/client/projectLead">Project Lead</v-list-item>
          <v-list-item to="/client/portfolioManager">Portfolio Manager</v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>

  <!-- <v-list-group class="dashboard-menu">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="mdi-book-education">Training</v-list-item>
      </template>

      <v-list-item to="/client/training/roadMap" title="Training RoadMaps" prepend-icon="mdi-account-school" value="RoadMap"></v-list-item>
      <v-list-item to="/client/training/courses" title="Courses" prepend-icon="mdi-school" value="Courses"></v-list-item>
      <v-list-item to="/client/training/assessment" title="Assessments" prepend-icon="mdi-book-clock" value="Assessment"></v-list-item>
    </v-list-group> -->
</template>

<script lang="ts">
  import store from "@/store"
  import { defineComponent } from "vue"

  export default defineComponent({
    props: ["isProjectLead", "isPortfolioManager", "isTDM", "isClient"],
    data() {
      return {
        resourceMenu: false,
        trainingMenu: false,
      }
    },
    methods: {
      GoToProject() {
        store.dispatch("setNewProjectPopupStatus", true)
        this.$router.push({ name: "management" })
      },
    },
    computed: {
      userIsClient() {
        return store.getters.userIsClient
      },
      userIsPortfolioManager() {
        return store.getters.userIsPortfolioManager
      },
    },
  })
</script>
<style scoped lang="scss">
  @import "node_modules/shared-components/assets/style/font.scss";

  a {
    color: $c_white;
  }
  .header-menu-container {
    display: flex;
    gap: 4px;
    width: 100%;
    align-items: center;
  }
  .header-menu-logo {
    max-width: 84px;
    height: 46px;
  }
  .header-menu {
    display: flex;
    gap: 5px;
    color: $c_white;
    margin: auto;
  }
  .header-menu-item {
    display: flex;
    text-decoration: none !important;
    cursor: pointer;
    .header-menu-item-content {
      @extend .f-sub-heading;
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: center;
      padding: 0 5px;
      min-width: 120px;
    }
    &:not(:first-child)::before {
      content: "";
      width: 1px;
      height: 100%;
      border: 1px solid $c_dim_gray;
    }
  }
</style>
