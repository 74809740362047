<template>
  <v-card class="nav-menu-container">
    <v-card-text>
      <img src="@/assets/images/code-clan-logo.svg" class="nav-menu-logo" />
      <v-list>
        <!-- <v-list-item
      to="/client/dashboard"
      prepend-icon="mdi-view-dashboard"
      v-if="!isTDM"
    >
      <v-list-item-content>
        <v-list-item-title>Dashboard</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->
        <v-list-item to="/client/productivity" v-if="isProjectLead || isPortfolioManager">
          <v-list-item-content>
            <v-list-item-title class="nav-menu-item">
              <img src="@/assets/images/icon/productivity.svg" />
              <span>Productivity</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/client/cost" v-if="isPortfolioManager">
          <v-list-item-content>
            <v-list-item-title class="nav-menu-item">
              <img src="@/assets/images/icon/cost.svg" />
              <span>Cost</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item
      to="/client/myProjects"
      prepend-icon="mdi-file-multiple"
      v-if="!isTDM"
    >
      <v-list-item-content>
        <v-list-item-title>My Projects</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->
        <v-list-group class="dashboard-menu">
          <template v-slot:activator="{ props }">
            <v-list-item class="nav-menu-item" v-bind="props">
              <v-list-item-content>
                <v-list-item-title class="nav-menu-item">
                  <img src="@/assets/images/icon/training.svg" />
                  <span>Training</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item to="/client/training/roadMap" title="Training RoadMaps" prepend-icon="mdi-account-school" value="RoadMap"></v-list-item>
          <v-list-item to="/client/training/courses" title="Courses" prepend-icon="mdi-school" value="Courses"></v-list-item>
          <v-list-item to="/client/training/assessment" title="Assessments" prepend-icon="mdi-book-clock" value="Assessment"></v-list-item>
        </v-list-group>
        <!-- <v-list-item to="/client/search/wizard" v-if="isPortfolioManager && false">
        <v-list-item-content>
          <v-list-item-title class="nav-menu-item">Search</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
        <v-list-item to="/client/management" v-if="isPortfolioManager || isClient">
          <v-list-item-content>
            <v-list-item-title class="nav-menu-item">
              <img src="@/assets/images/icon/management.svg" />
              <span>Management</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="">
          <v-list-item-content>
            <v-list-item-title class="nav-menu-item">
              <img src="@/assets/images/icon/security.svg" />
              <span>Security</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/client/planning" v-if="isPortfolioManager || isClient">
          <v-list-item-content>
            <v-list-item-title class="nav-menu-item">
              <img src="@/assets/images/icon/planning.svg" />
              <span>Planning</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group class="dashboard-menu" v-if="isClient || isPortfolioManager">
          <template v-slot:activator="{ props }">
            <v-list-item class="nav-menu-item" v-bind="props">
              <v-list-item-content>
                <v-list-item-title class="nav-menu-item">
                  <img src="@/assets/images/icon/quick-actions.svg" />
                  <span>Resource</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item to="/client/virtualDesktop">Smart Workbench</v-list-item>
          <v-list-item @click="GoToProject">Project</v-list-item>
          <v-list-item to="/client/projectLead">Project Lead</v-list-item>
          <v-list-item to="/client/portfolioManager">Portfolio Manager</v-list-item>
        </v-list-group>
        <!-- <v-list-item
      to="/client/virtualDesktop"
      v-if="isPortfolioManager || isClient"
      prepend-icon="mdi-laptop-account"
    >
      <v-list-item-content>
        <v-list-item-title>Virtual Desktop</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
  import store from "@/store"
  import { defineComponent } from "vue"

  export default defineComponent({
    props: ["isProjectLead", "isPortfolioManager", "isTDM", "isClient"],
    methods: {
      GoToProject() {
        store.dispatch("setNewProjectPopupStatus", true)
        this.$router.push({ name: "management" })
      },
    },
  })
</script>
<style scoped lang="scss">
  .nav-menu-container {
    min-width: 315px;
    .nav-menu-item {
      display: flex;
      gap: 10px;
      align-content: center;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .nav-menu-logo {
      max-width: 85px;
    }
  }
</style>
