import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "f-sub-heading c-white" }
const _hoisted_2 = { class: "f-body-2" }
const _hoisted_3 = { class: "f-body-2" }
const _hoisted_4 = { class: "f-body-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_row, { class: "ga-2 justify-space-between" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          sm: "8",
          md: "9"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "5",
                  class: "d-flex flex-column"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.orderModel.Title), 1),
                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.orderModel.Info1), 1),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.orderModel.Info2), 1),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.orderModel.Info3), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "2",
                  class: "f-sub-heading c-white"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("QTY: 1")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "5",
                  class: "d-flex flex-column ga-2"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createElementVNode("span", { class: "f-sub-heading c-white" }, "Estimated Monthly Cost: AUD 42", -1),
                    _createElementVNode("span", { class: "f-sub-heading c-white" }, "(AUD 0.21 per work hour)", -1)
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          sm: "3",
          md: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              class: "tertiary_btn w-100",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modify()))
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Modify")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("More Information On Transparent Pricing")
          ])),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}