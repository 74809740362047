<template>
  <v-row :class="[isTotal ? 'text-white pb-2' : '']">
    <v-col cols="12" class="total-cost-container">
      <h4>Estimated Monthly Cost AUD {{ getPerMonthPrice }}</h4>
      <h4>(AUD {{ getPerHourPrice }} per work hour)</h4>

      <span class="c-silver-2 f-body-1 hidden-sm-and-down">
        Costs are estimated based on usage time. All smart workbench virtual desktops are dedicated 24/7, but you only get charged for the hours you use.
      </span>
      <v-tooltip :text="getPriceTooltip()" max-width="300" v-if="!isTotal">
        <template v-slot:activator="{ props }">
          <span v-bind="props" class="f-body-1 hidden-sm-and-down">More Information on transparent pricing</span>
        </template>
      </v-tooltip>
    </v-col>
  </v-row>
</template>
<script lang="ts">
  import { defineComponent } from "vue"

  export default defineComponent({
    props: ["commitmentModel", "isTimesheetScreen", "isTotal"],
    data() {
      return {
        vmBasePerHour: 0.2,
        hoursPerMonth: 176,
        timeSheetBasePerHour: 0.1,
      }
    },
    async mounted() {},
    methods: {
      getTotalOfHoursPerMonth(price: number) {
        return price * this.hoursPerMonth
      },
      getPriceTooltip() {
        return !this.isTimesheetScreen
          ? "Pricing is calculated based on the hours of work not the uptime, even though Virtual Desktops are dedicated to the user 24 hours a day 7 days a week. For example, the cost of a Virtual Desktop instance that is dedicated to a user who works fulltime, is calculated based on 40 hours per week."
          : "The cost of timesheet management is calculated based on the actual work hours logged in the timesheet by the Team Member. For example, if they   log 40 hours per week in the timesheet, the timesheet management cost for that week will be calculated based on 40 hours"
      },
    },
    computed: {
      getVmBaseWithExtra() {
        const cpuExtra = this.commitmentModel.VirtualDesktopSpec.Cpu / 100
        const ramExtra = this.commitmentModel.VirtualDesktopSpec.Ram / 100
        const diskExtra = this.commitmentModel.VirtualDesktopSpec.Disk / 100

        return this.vmBasePerHour + cpuExtra + ramExtra + diskExtra
      },
      getPerHourPrice() {
        if (this.isTotal) {
          return (this.getVmBaseWithExtra + this.getTimesheetPerHourBase).toFixed(2)
        } else if (this.isTimesheetScreen) {
          return this.getTimesheetPerHourBase.toFixed(2)
        } else {
          return this.getVmBaseWithExtra.toFixed(2)
        }
      },
      getPerMonthPrice() {
        if (this.isTotal) {
          return this.getTotalOfHoursPerMonth(this.getVmBaseWithExtra + this.getTimesheetPerHourBase).toFixed(2)
        } else if (this.isTimesheetScreen) {
          return this.getTotalOfHoursPerMonth(this.getTimesheetPerHourBase).toFixed(2)
        } else {
          return this.getTotalOfHoursPerMonth(this.getVmBaseWithExtra).toFixed(2)
        }
      },
      getTimesheetPerHourBase() {
        return this.commitmentModel.EnableTimesheetProcessing ? this.timeSheetBasePerHour : 0
      },
    },
  })
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/style.scss";
  .total-cost-container {
    display: flex;
    flex-direction: column;
    padding: 27px 44px;
    border: 1px solid;
    gap: 16px;
    @extend .b-r-10;
  }
  .v-theme--dark {
    .total-cost-container {
      border-color: $c_dim_gray;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .total-cost-container {
      padding: 16px 32px;
      border: none;
    }
  }
</style>
