import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/images/code-clan-logo.svg'
import _imports_1 from '@/assets/images/icon/productivity.svg'
import _imports_2 from '@/assets/images/icon/cost.svg'
import _imports_3 from '@/assets/images/icon/training.svg'
import _imports_4 from '@/assets/images/icon/management.svg'
import _imports_5 from '@/assets/images/icon/security.svg'
import _imports_6 from '@/assets/images/icon/planning.svg'
import _imports_7 from '@/assets/images/icon/quick-actions.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "nav-menu-container" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _cache[11] || (_cache[11] = _createElementVNode("img", {
            src: _imports_0,
            class: "nav-menu-logo"
          }, null, -1)),
          _createVNode(_component_v_list, null, {
            default: _withCtx(() => [
              (_ctx.isProjectLead || _ctx.isPortfolioManager)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 0,
                    to: "/client/productivity"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_content, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, { class: "nav-menu-item" }, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                              _createElementVNode("img", { src: _imports_1 }, null, -1),
                              _createElementVNode("span", null, "Productivity", -1)
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.isPortfolioManager)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 1,
                    to: "/client/cost"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_content, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, { class: "nav-menu-item" }, {
                            default: _withCtx(() => _cache[1] || (_cache[1] = [
                              _createElementVNode("img", { src: _imports_2 }, null, -1),
                              _createElementVNode("span", null, "Cost", -1)
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_v_list_group, { class: "dashboard-menu" }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_list_item, _mergeProps({ class: "nav-menu-item" }, props), {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_content, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, { class: "nav-menu-item" }, {
                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                              _createElementVNode("img", { src: _imports_3 }, null, -1),
                              _createElementVNode("span", null, "Training", -1)
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1040)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item, {
                    to: "/client/training/roadMap",
                    title: "Training RoadMaps",
                    "prepend-icon": "mdi-account-school",
                    value: "RoadMap"
                  }),
                  _createVNode(_component_v_list_item, {
                    to: "/client/training/courses",
                    title: "Courses",
                    "prepend-icon": "mdi-school",
                    value: "Courses"
                  }),
                  _createVNode(_component_v_list_item, {
                    to: "/client/training/assessment",
                    title: "Assessments",
                    "prepend-icon": "mdi-book-clock",
                    value: "Assessment"
                  })
                ]),
                _: 1
              }),
              (_ctx.isPortfolioManager || _ctx.isClient)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 2,
                    to: "/client/management"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_content, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, { class: "nav-menu-item" }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createElementVNode("img", { src: _imports_4 }, null, -1),
                              _createElementVNode("span", null, "Management", -1)
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_v_list_item, { to: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, { class: "nav-menu-item" }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createElementVNode("img", { src: _imports_5 }, null, -1),
                          _createElementVNode("span", null, "Security", -1)
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.isPortfolioManager || _ctx.isClient)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 3,
                    to: "/client/planning"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_content, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, { class: "nav-menu-item" }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createElementVNode("img", { src: _imports_6 }, null, -1),
                              _createElementVNode("span", null, "Planning", -1)
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.isClient || _ctx.isPortfolioManager)
                ? (_openBlock(), _createBlock(_component_v_list_group, {
                    key: 4,
                    class: "dashboard-menu"
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_list_item, _mergeProps({ class: "nav-menu-item" }, props), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_content, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, { class: "nav-menu-item" }, {
                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                  _createElementVNode("img", { src: _imports_7 }, null, -1),
                                  _createElementVNode("span", null, "Resource", -1)
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, { to: "/client/virtualDesktop" }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode("Smart Workbench")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item, { onClick: _ctx.GoToProject }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode("Project")
                        ])),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_v_list_item, { to: "/client/projectLead" }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("Project Lead")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item, { to: "/client/portfolioManager" }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("Portfolio Manager")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}