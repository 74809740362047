import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Transition as _Transition, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-100" }
const _hoisted_2 = { class: "d-md-none d-flex ga-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_NavigationMenu = _resolveComponent("NavigationMenu")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_HeaderTitle = _resolveComponent("HeaderTitle")!
  const _component_HeaderMenu = _resolveComponent("HeaderMenu")!
  const _component_ProfileDropdown = _resolveComponent("ProfileDropdown")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_app_bar, {
      app: "",
      class: "appBarComponent"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_menu, {
            modelValue: _ctx.hamburgurMenu,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hamburgurMenu) = $event)),
            location: "bottom",
            transition: "scale-transition"
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_app_bar_nav_icon, _mergeProps({ class: "hamburgur-toggler" }, props), null, 16)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_NavigationMenu, {
                isAuthenticated: _ctx.isAuthenticated,
                isProjectLead: _ctx.isProjectLead,
                isPortfolioManager: _ctx.isPortfolioManager,
                isClient: _ctx.isClient,
                isTDM: _ctx.isTDM
              }, null, 8, ["isAuthenticated", "isProjectLead", "isPortfolioManager", "isClient", "isTDM"])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_HeaderTitle)
        ]),
        _createVNode(_component_HeaderMenu, {
          class: "hidden-sm-and-down",
          isProjectLead: _ctx.isProjectLead,
          isPortfolioManager: _ctx.isPortfolioManager,
          isClient: _ctx.isClient,
          isTDM: _ctx.isTDM
        }, null, 8, ["isProjectLead", "isPortfolioManager", "isClient", "isTDM"]),
        _createVNode(_component_ProfileDropdown)
      ]),
      _: 1
    }),
    _createVNode(_component_v_main, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_container, { fluid: "" }, {
          default: _withCtx(() => [
            _createVNode(_Transition, {
              name: "slide-y-transition",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_view)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}