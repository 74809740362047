import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-title mb-4" }
const _hoisted_2 = { class: "card-title-left" }
const _hoisted_3 = { class: "v-card-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_NumberField = _resolveComponent("NumberField")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    ref: "frmMain",
    modelValue: _ctx.valid,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.valid) = $event)),
    "lazy-validation": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.isNew == true ? "Add a new Position" : "Update Position Details"), 1)
          ]),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "align-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        dense: "",
                        label: "Role",
                        "item-title": "Name",
                        "item-value": "id",
                        rules: [_ctx.rules.required],
                        items: _ctx.getJobRoles,
                        required: "",
                        modelValue: _ctx.model.JobRoleId,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.JobRoleId) = $event)),
                        outlined: ""
                      }, null, 8, ["rules", "items", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        dense: "",
                        clearable: "",
                        label: "Job Titles",
                        "item-title": "Name",
                        "item-value": "id",
                        chips: "",
                        "closable-chips": "",
                        multiple: "",
                        items: _ctx.getJobTitle,
                        rules: [_ctx.rules.required],
                        required: "",
                        modelValue: _ctx.model.JobTitles,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.JobTitles) = $event)),
                        outlined: ""
                      }, null, 8, ["items", "rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NumberField, {
                        dense: "",
                        label: "Number of Seats",
                        rules: [_ctx.rules.required, _ctx.rules.minNumber(1)],
                        modelValue: _ctx.model.NoOfPositions,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.NoOfPositions) = $event)),
                        outlined: "",
                        min: 1
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DateInputField, {
                        label: "Start date",
                        rules: [_ctx.asapCheckBox || _ctx.rules.required],
                        "first-day-of-week": "1",
                        modelValue: _ctx.model.StartDate,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.StartDate) = $event)),
                        "hide-actions": "",
                        placeholder: "Start date",
                        "prepend-icon": "",
                        disabled: _ctx.asapCheckBox,
                        min: _ctx.getTodayDate
                      }, null, 8, ["rules", "modelValue", "disabled", "min"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_checkbox, {
                        "hide-details": "",
                        label: "ASAP",
                        modelValue: _ctx.asapCheckBox,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.asapCheckBox) = $event)),
                        onChange: _ctx.asapChanged
                      }, null, 8, ["modelValue", "onChange"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_divider),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        dense: "",
                        label: "Skills",
                        "item-title": "Text",
                        "item-value": "id",
                        items: _ctx.sortedSkills,
                        chips: "",
                        "closable-chips": "",
                        multiple: "",
                        modelValue: _ctx.model.Skills,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.Skills) = $event)),
                        outlined: ""
                      }, null, 8, ["items", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        dense: "",
                        label: "Country",
                        "item-title": "text",
                        "item-value": "value",
                        items: _ctx.sortedLocations,
                        loading: _ctx.isCountryLoading,
                        disabled: _ctx.isCountryLoading,
                        multiple: "",
                        chips: "",
                        "closable-chips": "",
                        outlined: "",
                        modelValue: _ctx.model.CountryId,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.CountryId) = $event))
                      }, null, 8, ["items", "loading", "disabled", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextAreaField, {
                        dense: "",
                        label: "Job Description",
                        modelValue: _ctx.model.JobDescription,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.JobDescription) = $event)),
                        outlined: ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  class: "right primary_btn",
                  onClick: _ctx.save,
                  loading: _ctx.loading,
                  disabled: _ctx.loading
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("mdi-check")
                      ])),
                      _: 1
                    }),
                    _cache[10] || (_cache[10] = _createTextVNode(" Save "))
                  ]),
                  _: 1
                }, 8, ["onClick", "loading", "disabled"]),
                _createVNode(_component_v_btn, {
                  class: "right mr-5 secondary_btn",
                  onClick: _ctx.cancel,
                  disabled: _ctx.loading
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode("cancel")
                  ])),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}