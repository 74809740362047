import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header-title" }
const _hoisted_2 = { class: "f-sub-heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_app_bar_nav_icon, { class: "nav-icon" }, {
      default: _withCtx(() => [
        _createElementVNode("i", {
          class: _normalizeClass(["icon v-icon", {
          notranslate: true,
          mdi: true,
          dark: false,
          [_ctx.icon]: true,
        }])
        }, null, 2)
      ]),
      _: 1
    }),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1)
  ]))
}