import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card-title mb-4" }
const _hoisted_2 = { class: "card-title-left" }
const _hoisted_3 = { key: 3 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_VirtualDesktopAddon = _resolveComponent("VirtualDesktopAddon")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.showActionDialog,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showActionDialog) = $event)),
    persistent: "",
    "max-width": "800px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_form, { ref: "mainForm" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            loading: _ctx.loading,
            disabled: _ctx.loading
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.commitmentAction.DisplayName), 1),
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "card-title-right" }, null, -1))
              ]),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DateInputField, {
                            label: "Effective Date",
                            rules: [_ctx.rules.required],
                            "first-day-of-week": "1",
                            modelValue: _ctx.selectedEffectiveDate,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedEffectiveDate) = $event)),
                            "hide-actions": "",
                            placeholder: "Effective Date",
                            "prepend-icon": "",
                            min: _ctx.getMinDate,
                            max: _ctx.getMaxDate
                          }, null, 8, ["rules", "modelValue", "min", "max"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.commitmentAction.Type == _ctx.actionsEnum.CustomerAddVirtualDesktop || _ctx.commitmentAction.Type == _ctx.actionsEnum.CustomerModifyVirtualDesktop)
                    ? (_openBlock(), _createBlock(_component_VirtualDesktopAddon, {
                        key: 0,
                        showEnableCheckBox: false,
                        virtualDesktopSpec: _ctx.virtualDesktopSpec,
                        diskValues: _ctx.diskValues,
                        cpuValues: _ctx.cpuValues,
                        ramValues: _ctx.ramValues
                      }, null, 8, ["virtualDesktopSpec", "diskValues", "cpuValues", "ramValues"]))
                    : _createCommentVNode("", true),
                  (_ctx.commitmentAction.Type == _ctx.actionsEnum.CustomerTerminateVirtualDesktop)
                    ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_checkbox, {
                                rules: [_ctx.rules.required],
                                label: "Are you sure you want to terminate this virtual desktop?"
                              }, null, 8, ["rules"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.commitmentAction.Type == _ctx.actionsEnum.CustomerExtendContract)
                    ? (_openBlock(), _createBlock(_component_v_row, { key: 2 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_DateInputField, {
                                label: "Extend To Date",
                                rules: [_ctx.rules.required],
                                "first-day-of-week": "1",
                                modelValue: _ctx.extendContractDate,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.extendContractDate) = $event)),
                                "hide-actions": "",
                                placeholder: "Extend To Date",
                                "prepend-icon": "",
                                year: _ctx.getExtendContractMinDate.getFullYear(),
                                month: _ctx.getExtendContractMinDate.getMonth(),
                                min: _ctx.getExtendContractMinDate.toDateString()
                              }, null, 8, ["rules", "modelValue", "year", "month", "min"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.commitmentAction.Type == _ctx.actionsEnum.CustomerTerminateContract)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        (_ctx.terminateContract.HasVm)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                      (_ctx.terminateContract.RelatedCommitments.length == 0)
                                        ? (_openBlock(), _createElementBlock("p", _hoisted_5, " This Engagement, has a Virtual Desktop Instance. the instance will be decommission with this termination "))
                                        : (_openBlock(), _createElementBlock("p", _hoisted_6, "This Engagement, has a Virtual Desktop Instance. You can choose to move the subscription to another engagement or decommission the instance."))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_radio_group, {
                                        modelValue: _ctx.terminateContract.VdState,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.terminateContract.VdState) = $event)),
                                        rules: [_ctx.rules.required]
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_radio, {
                                            label: "Decommission Virtual Desktop on the Effective Date",
                                            value: "Decommission"
                                          }),
                                          (_ctx.terminateContract.RelatedCommitments.length > 0)
                                            ? (_openBlock(), _createBlock(_component_v_radio, {
                                                key: 0,
                                                label: "Transfer Virtual Desktop to another Engagement contract",
                                                value: "Transfer"
                                              }))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 1
                                      }, 8, ["modelValue", "rules"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      (_ctx.terminateContract.VdState == 'Transfer')
                                        ? (_openBlock(), _createBlock(_component_SelectField, {
                                            key: 0,
                                            label: "Transfer To Contract",
                                            rules: [_ctx.rules.required],
                                            modelValue: _ctx.terminateContract.SelectedTransferCommitmentId,
                                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.terminateContract.SelectedTransferCommitmentId) = $event)),
                                            placeholder: "Transfer To Contract",
                                            items: _ctx.terminateContract.RelatedCommitments,
                                            "item-title": "Name",
                                            "item-value": "Id"
                                          }, null, 8, ["rules", "modelValue", "items"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    disabled: _ctx.loading,
                    loading: _ctx.loading,
                    class: "secondary_btn",
                    onClick: _ctx.closeDialog
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Cancel")
                    ])),
                    _: 1
                  }, 8, ["disabled", "loading", "onClick"]),
                  _createVNode(_component_v_btn, {
                    disabled: _ctx.loading,
                    loading: _ctx.loading,
                    class: "primary_btn",
                    onClick: _ctx.applyAction
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("Apply")
                    ])),
                    _: 1
                  }, 8, ["disabled", "loading", "onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["loading", "disabled"])
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}