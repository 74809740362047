<template>
  <v-card class="container">
    <v-card-text>
      <v-form ref="frmMain" v-model="valid" lazy-validation class="form-inputs">
        <v-row>
          <v-col cols="12">
            <TextField v-model="questionModel.Title" label="Title" :rules="[rules.required]" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <AutoCompleteField
              v-model="selectedQuestionTechnology"
              label="Technology"
              prepend-icon="mdi-application-brackets-outline"
              :items="technologies"
              item-title="Text"
              item-value="id"
              clearable
              multiple />
          </v-col>
          <v-col cols="6">
            <SelectField
              v-model="questionModel.Level"
              label="Level"
              prepend-icon="mdi-arrow-up-bold-outline"
              :items="['Easy', 'Medium', 'Hard']"
              clearable
              :rules="[rules.required]" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <SelectField v-model="selectedEditors" label="Editor" prepend-icon="mdi-file-code-outline" :items="editors" item-title="Value" item-value="Id" multiple clearable />
          </v-col>
          <v-col cols="6">
            <NumberField v-model="questionModel.Duration" label="Duration/mins" prepend-icon="mdi-clock-time-twelve-outline" :rules="[rules.required]" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <TextAreaField v-model="questionModel.Question" label="Questions" rows="4" prepend-icon="mdi-tooltip-question-outline" :rules="[rules.required]" clearable />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <TextAreaField v-model="questionModel.Answer" label="Answer" rows="3" prepend-icon="mdi-tooltip-question-outline" clearable />
          </v-col>
        </v-row>
        <v-row class="mb-5">
          <v-col cols="12">
            <v-btn @click="addEvaluation()" prepend-icon="mdi-plus" class="secondary_btn">Add Evaluation</v-btn>
          </v-col>
        </v-row>
        <v-row v-for="(evaluation, index) in evaluatons" :key="index" class="evaluation-list align-center">
          <v-col cols="8">
            <TextAreaField v-model="evaluation.Criteria" label="Evaluation" rows="1" prepend-icon="mdi-pencil" :rules="[rules.required]" clearable />
          </v-col>
          <v-col cols="3">
            <SelectField v-model="evaluation.Point" label="Point" prepend-icon="mdi-pen-plus" :items="pointItems" :rules="[rules.required]" />
          </v-col>
          <v-col cols="1">
            <v-btn @click="deleteEvaluation(index)" icon="mdi-delete" variant="text" label="Delete"></v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions class="mt-5">
      <v-row>
        <v-col cols="12" class="action-btns">
          <v-btn @click="close" class="secondary_btn">Close</v-btn>
          <v-btn @click="save" class="primary_btn_v2">{{ question != null ? "Save" : "Add" }}</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
  import { defineComponent } from "vue"
  import rules from "shared-components/src/utils/validations"
  import { Evaluation, Question } from "../../../../../ProjectPortal-SharedComponents/src/models/AssessmentModel"
  import Editor from "shared-components/src/models/Editor"
  import CoreSkill from "shared-components/src/models/CoreSkill"

  export default defineComponent({
    props: {
      question: {
        type: Object as () => Question | null,
      },
      editors: {
        type: Array as () => Editor[],
      },
      technologies: {
        type: Array as () => CoreSkill[],
      },
    },
    data() {
      return {
        rules,
        valid: false,
        questionModel: {} as Question,
        selectedEditors: [] as string[],
        selectedQuestionTechnology: [],
        evaluatons: [] as Evaluation[],
        pointItems: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        technologies1: [{ title: "asd", value: "das" }],
      }
    },
    mounted() {
      if (this.question) {
        this.questionModel = this.question
        if (this.questionModel.Technologies && this.questionModel.Technologies.length > 0) {
          ;(this.selectedQuestionTechnology as any) = this.questionModel.Technologies.map((item) => item.id)
        }
        if (this.questionModel.Editors && this.questionModel.Editors.length > 0) {
          this.selectedEditors = this.questionModel.Editors.map((item) => item.Id)
        }
        if (this.questionModel.Evaluations && this.questionModel.Evaluations.length > 0) {
          this.evaluatons = this.questionModel.Evaluations
        }
      }
    },
    methods: {
      addEvaluation() {
        this.evaluatons.push({ Criteria: "", Point: 0 })
      },
      deleteEvaluation(index: number) {
        this.evaluatons.splice(index, 1)
      },
      async save() {
        const isValid = await (this.$refs.frmMain as any).validate()
        if (isValid.valid) {
          this.questionModel.Technologies = this.selectedQuestionTechnology.map((item) => {
            return { id: item, Text: "" } as CoreSkill
          })
          this.questionModel.Editors = this.selectedEditors.map((item) => {
            return { Id: item, Value: "" } as Editor
          })
          this.questionModel.Evaluations = this.evaluatons
          this.$emit("saveQuestion", this.questionModel)
        }
      },
      close() {
        this.$emit("closeQuestionModal")
      },
    },
  })
</script>
<style lang="scss" scoped>
  .container {
    height: 100%;
    .action-btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      padding: 0 30px;
    }
    .evaluation-list {
      margin-top: 5px;
    }
  }
</style>
