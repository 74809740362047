<template>
  <v-card class="border-white-1" v-if="serviceModel">
    <v-card-text>
      <div class="d-flex flex-column ga-2 align-center">
        <h4>{{ serviceModel.Title }}</h4>
        <img src="@/assets/images/timesheet-processing.svg" class="service-item-image" v-if="type == 'TimesheetProcessing'" />
        <img src="@/assets/images/code-integration.svg" class="service-item-image" v-if="type == 'CodeScan'" />
        <h4>+ ${{ serviceModel.Price }} per month</h4>
        <span class="f-body-1 text-center">Learn more about Code Integration</span>
        <v-btn class="tertiary_btn" @click="add">Add</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
  import { defineComponent } from "vue"

  export default defineComponent({
    props: ["type"],
    components: {},
    data() {
      return {}
    },
    async mounted() {},
    methods: {
      add() {
        this.$emit("add")
      },
    },
    computed: {
      serviceModel() {
        if (this.type == "TimesheetProcessing") {
          return {
            Title: "Timesheet Processing",
            Price: 10,
            About: "",
          }
        }
        if (this.type == "CodeScan") {
          return {
            Title: "Code Scan",
            Price: 10,
            About: "",
          }
        }
      },
    },
  })
</script>
<style scoped lang="scss">
  .service-item-image {
    width: 100%;
    max-width: 250px;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .service-item-image {
      max-width: 150px;
    }
  }
</style>
