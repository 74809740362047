<template>
  <div class="profile-dropdown" v-closeOnClick @click.prevent="TOGGLE_PROFILE_DROPDOWN()" v-if="clientInfo && clientInfo.email">
    <NotificationDropDown :userId="clientInfo.userId" :isClient="true" class="notification-drp" zIndex="3000" :viewAllRoute="`/client/notifications`" />
    <v-menu v-model="profileMenu" location="bottom">
      <template v-slot:activator="{ props }">
        <div class="user-dropdown" v-bind="props">
          <div class="user-name hidden-md-and-down">{{ clientInfo.fullName }}</div>
          <v-icon>mdi-account-circle</v-icon>
        </div>
      </template>

      <v-card min-width="300">
        <v-card-text>
          <v-card class="menu-user-email">
            <div>
              <router-link to="/client/dashboard">
                {{ clientInfo.email }}
              </router-link>
            </div>
          </v-card>

          <v-list class="">
            <v-list-item to="/client/dashboard">
              <v-list-item-content>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/logout">
              <v-list-item-content>
                <v-list-item-title>Log Out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from "vue"
  import { mapGetters, mapMutations } from "vuex"
  import { TOGGLE_PROFILE_DROPDOWN, CLOSE_PROFILE_DROPDOWN } from "@/store/types"
  import NotificationDropDown from "shared-components/src/components/Notification/NotificationDropDown.vue"
  import store from "@/store"

  export default defineComponent({
    data() {
      return {
        showDetailPopUp: false,
        selectedNotification: {},
        isthereNewNotif: false,
        notSeenNotfCount: 0,
        profileMenu: false,
      }
    },
    computed: {
      ...mapGetters(["clientInfo", "profileDropdown"]),
      hideName() {
        return !this.profileDropdown && this.$vuetify.display.name === "xs"
      },
      userIsClient() {
        return store.getters.userIsClient
      },
      userIsPortfolioManager() {
        return store.getters.userIsPortfolioManager
      },
    },
    components: { NotificationDropDown },
    methods: {
      clickedOutside() {
        this.showDetailPopUp = false
      },
      ...mapMutations([TOGGLE_PROFILE_DROPDOWN, CLOSE_PROFILE_DROPDOWN]),
    },
    directives: {
      closeOnClick: {
        beforeMount(el, binding) {
          document.addEventListener("click", (event) => onClick(event, el))
          function onClick(event: any, element: any) {
            const clickedELement = event.target
            const container = element
            let clickedItemSelector
            if (clickedELement.classList.value !== "") {
              clickedItemSelector = "." + clickedELement.classList.value.split(" ").join(".")
            } else if (clickedELement.id) {
              clickedItemSelector = "#" + clickedELement.id
            } else if (clickedELement.parentElement && clickedELement.parentElement.classList) {
              clickedItemSelector = "." + clickedELement.parentElement.classList.value.split(" ").join(".") + " " + clickedELement.tagName
            }
            try {
              const isInContainer = container.querySelector(clickedItemSelector) != null
              if (!isInContainer) {
                ;(binding.instance as any).CLOSE_PROFILE_DROPDOWN()
              }
            } catch (e) {
              ;(binding.instance as any).CLOSE_PROFILE_DROPDOWN()
            }
          }
        },
      },
    },
  })
</script>
<style scoped lang="scss">
  .profile-dropdown {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    color: $c_white;
    transition: all 0.2s;
    align-items: center;
    gap: 5px;
    .user-dropdown {
      display: flex;
      gap: 16px;
      padding: 11px;
      padding-right: 0;
      cursor: pointer;
      width: max-content;
    }
    .notification-drp {
      .v-btn {
        .v-icon {
          color: $c_white !important;
        }
      }
    }
  }

  .menu-user-email {
    background-color: $c_bittersweet_2 !important;
    width: 100%;
    height: 45px;
    padding: 12px !important;
    text-align: center;
    text-transform: uppercase;
    a {
      color: $c_white !important;
      text-decoration: none;
    }
  }
</style>
