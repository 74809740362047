import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "user-name hidden-md-and-down" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationDropDown = _resolveComponent("NotificationDropDown")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _directive_closeOnClick = _resolveDirective("closeOnClick")!

  return (_ctx.clientInfo && _ctx.clientInfo.email)
    ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "profile-dropdown",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.TOGGLE_PROFILE_DROPDOWN()), ["prevent"]))
      }, [
        _createVNode(_component_NotificationDropDown, {
          userId: _ctx.clientInfo.userId,
          isClient: true,
          class: "notification-drp",
          zIndex: "3000",
          viewAllRoute: `/client/notifications`
        }, null, 8, ["userId"]),
        _createVNode(_component_v_menu, {
          modelValue: _ctx.profileMenu,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.profileMenu) = $event)),
          location: "bottom"
        }, {
          activator: _withCtx(({ props }) => [
            _createElementVNode("div", _mergeProps({ class: "user-dropdown" }, props), [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.clientInfo.fullName), 1),
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("mdi-account-circle")
                ])),
                _: 1
              })
            ], 16)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_card, { "min-width": "300" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, { class: "menu-user-email" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_router_link, { to: "/client/dashboard" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.clientInfo.email), 1)
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_list, { class: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item, { to: "/client/dashboard" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_content, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, null, {
                                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createTextVNode("Dashboard")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_list_item, { to: "/logout" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_content, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, null, {
                                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                                    _createTextVNode("Log Out")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])), [
        [_directive_closeOnClick]
      ])
    : _createCommentVNode("", true)
}