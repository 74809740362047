<template>
  <div class="chart-line-container">
    <Line :data="chartData" :options="lineOptions" />
    <v-progress-circular :size="50" indeterminate v-if="chartData.datasets.length == 0" class="chart-loading"></v-progress-circular>
  </div>
</template>

<script lang="ts">
  import { Line } from "vue-chartjs"
  import ChartDataWorker from "worker-loader!./chartData.ts" // Use worker-loader or equivalent for Webpack.
  import { defineComponent } from "vue"
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, TimeScale } from "chart.js"
  import "chartjs-adapter-date-fns"

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, TimeScale)

  export default defineComponent({
    components: { Line },
    data() {
      return {
        chartData: {
          labels: [],
          datasets: [],
        },
        lineOptions: {
          responsive: true,
          animation: false,
          maintainAspectRatio: false,
          elements: {
            point: {
              radius: 0,
            },
          },
          scales: {
            y: {
              min: 0,
              max: 400,
              ticks: {
                display: false,
                stepSize: 50,
                autoSkip: false,
              },
            },
            x: {
              type: "time",
              time: {
                unit: "minute",
                displayFormats: {
                  day: "EEE",
                },
                tooltipFormat: "EEE, MMM d, h:mm a",
              },
              ticks: {
                source: "auto",
                maxRotation: 0,
                callback: function (value: any, index: any, ticks: any) {
                  const date = new Date(value)
                  if (date.getHours() === 0 && date.getMinutes() === 0) {
                    return date.toLocaleDateString("en-US", { weekday: "short" })
                  }
                  return null
                },
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context: any) {
                  if (context.dataset.label != "Latency") {
                    return `${context.dataset.label}: ${context.raw / 4}%`
                  }
                  return `${context.dataset.label}: ${context.raw}`
                },
              },
            },
          },
        },
      }
    },
    mounted() {
      this.initWorker()
    },
    methods: {
      initWorker() {
        const worker = new ChartDataWorker()
        const serializedTm = JSON.parse(JSON.stringify(this.tm))
        worker.onerror = (error) => {
          console.error("Worker encountered an error:", error.message)
        }
        worker.onmessage = (event: any) => {
          this.chartData = { ...event.data }
          worker.terminate()
        }

        worker.postMessage({ tm: serializedTm })
      },
    },
    props: ["tm"],
  })
</script>
<style lang="scss" scoped>
  .chart-line-container {
    position: relative;
    .chart-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $c_imperial_red;
    }
  }
</style>
