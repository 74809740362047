import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "actionBtns mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_AddOfficeLocation = _resolveComponent("AddOfficeLocation")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_form, { ref: "mainForm" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { flat: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_label, null, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode("Location Type")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_radio_group, {
                          inline: "",
                          modelValue: _ctx.model.LocationType,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.LocationType) = $event)),
                          rules: [_ctx.isJdSearch || _ctx.rules.required]
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_radio, {
                              label: "Remote",
                              value: "Remote"
                            }),
                            _createVNode(_component_v_radio, {
                              label: "On-Site",
                              value: "OnSite"
                            }),
                            _createVNode(_component_v_radio, {
                              label: "Hybrid",
                              value: "Hybrid"
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue", "rules"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          rules: [_ctx.isJdSearch || _ctx.rules.required],
                          label: "Timezone",
                          modelValue: _ctx.model.TimeZone,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.TimeZone) = $event)),
                          items: _ctx.timeZones,
                          outlined: "",
                          "return-object": false
                        }, null, 8, ["rules", "modelValue", "items"])
                      ]),
                      _: 1
                    }),
                    (_ctx.model.LocationType != 'Hybrid')
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          cols: "4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              rules: [_ctx.isJdSearch || _ctx.rules.required],
                              label: "Days of Week",
                              modelValue: _ctx.model.WorkingDays,
                              "onUpdate:modelValue": [
                                _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.WorkingDays) = $event)),
                                _ctx.DayOfWeekChanged
                              ],
                              items: _ctx.workingDays,
                              multiple: "",
                              outlined: "",
                              "return-object": false
                            }, null, 8, ["rules", "modelValue", "items", "onUpdate:modelValue"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_col, { cols: "2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          rules: [_ctx.isJdSearch || _ctx.rules.required],
                          label: "From",
                          modelValue: _ctx.model.From,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.From) = $event)),
                          items: _ctx.hoursInDay
                        }, null, 8, ["rules", "modelValue", "items"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { cols: "2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          rules: [_ctx.isJdSearch || _ctx.rules.required],
                          label: "To",
                          modelValue: _ctx.model.To,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.To) = $event)),
                          items: _ctx.hoursInDay
                        }, null, 8, ["rules", "modelValue", "items"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    (_ctx.model.LocationType != 'Remote')
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          cols: "4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              outlined: "",
                              rules: [_ctx.isJdSearch || _ctx.rules.required],
                              items: _ctx.officeLocations,
                              "item-title": "Name",
                              "item-value": "Id",
                              label: "Office Location",
                              showRequired: "",
                              modelValue: _ctx.model.OfficeLocationId,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.OfficeLocationId) = $event)),
                              "return-object": false,
                              "append-icon": "mdi-plus",
                              "onClick:append": _cache[6] || (_cache[6] = ($event: any) => (_ctx.showAddOfficeLocation = true))
                            }, null, 8, ["rules", "items", "modelValue"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.model.LocationType == 'Hybrid')
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 1,
                          cols: "4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              modelValue: _ctx.model.NoOfficeDaysPerWeek,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.NoOfficeDaysPerWeek) = $event)),
                              rules: [_ctx.isJdSearch || _ctx.rules.required],
                              label: "No. Of Office Days Per Week",
                              items: _ctx.NoOfficeDaysPerWeek,
                              outlined: ""
                            }, null, 8, ["modelValue", "rules", "items"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.model.LocationType == 'Hybrid')
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 2,
                          cols: "4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              modelValue: _ctx.model.WorkingDaysInOffice,
                              "onUpdate:modelValue": [
                                _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.WorkingDaysInOffice) = $event)),
                                _ctx.DaysInOfficeChanged
                              ],
                              rules: [_ctx.isJdSearch || _ctx.rules.required],
                              label: "Days Of Week In The Office",
                              items: _ctx.dayInOffice,
                              multiple: "",
                              outlined: "",
                              chips: "",
                              "return-object": false
                            }, null, 8, ["modelValue", "rules", "items", "onUpdate:modelValue"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_col, { cols: "2" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_btn, {
        class: "secondary_btn",
        onClick: _ctx.backStep
      }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [
          _createTextVNode("Previous")
        ])),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_v_btn, {
        class: "primary_btn",
        onClick: _ctx.nextStep
      }, {
        default: _withCtx(() => _cache[13] || (_cache[13] = [
          _createTextVNode("Next")
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showAddOfficeLocation,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.showAddOfficeLocation) = $event)),
      persistent: "",
      "max-width": "400px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AddOfficeLocation, {
          onCancel: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showAddOfficeLocation = false)),
          onSaveLocation: _ctx.saveOfficeLocation
        }, null, 8, ["onSaveLocation"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}