<template>
  <v-card class="b-eerie-black" v-if="finalModel" :loading="loading" :disabled="loading">
    <v-card-text>
      <h1>Complete Setup</h1>
      <div class="setup-complete-container">
        <div>
          <h4>Order Summary</h4>
          <v-card class="mt-2">
            <v-card-text>
              <OrderItem :model="finalModel" type="VirtualDesktop" @modify="modifyVd" />
              <div v-if="finalModel.EnableTimesheetProcessing" class="mt-3" @modify="modifyVd">
                <v-divider />
                <OrderItem
                  class="mt-3"
                  :projectLeads="projectLeads"
                  :publicHolidayZones="publicHolidayZones"
                  :model="finalModel"
                  type="TimesheetProcessing"
                  v-if="finalModel.EnableTimesheetProcessing"
                  @modify="showTimesheetProcessingModal" />
              </div>
            </v-card-text>
          </v-card>
          <v-divider class="mt-6 mb-6" />
          <h4>Add Services To Manage Your Team</h4>
          <v-row class="mt-2 mb-6">
            <v-col cols="12" sm="6" md="4" v-if="!finalModel.EnableTimesheetProcessing">
              <ServiceItem @add="showTimesheetProcessingModal" type="TimesheetProcessing" />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <ServiceItem type="CodeScan" />
            </v-col>
          </v-row>
          <v-card class="border-white-1">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <div class="d-flex flex-column ga-2">
                    <h4 class="c-silver-2">Estimated Total Monthly Cost:</h4>
                    <h4>AUD 45.66</h4>
                    <h4>($0.21 per work hour)</h4>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="d-flex align-end justify-end">
                  <v-btn class="primary_btn setup-complete-btn" @click="createVm">Complete Setup</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-card-text>
  </v-card>
  <v-dialog v-if="showTimesheetProcessing" v-model="showTimesheetProcessing" max-width="1000" @keydown.esc="showTimesheetProcessing = false">
    <TimesheetProcessing
      :projectLeads="projectLeads"
      :portfolioManagers="portfolioManagers"
      :publicHolidayZones="publicHolidayZones"
      @cancel="closeTimesheetProcessingModal"
      @save="saveTimesheetProcessing"
      :model="finalModel" />
  </v-dialog>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import VirtualDesktopAddon from "shared-components/src/components/Commitment/Addons/VirtualDesktopAddon.vue"
  import store from "@/store"
  import { ClientCreateVMRequest } from "shared-components/src/services/openApi/api"
  import CommitmentService from "@/services/CommitmentService"
  import PriceCalculator from "./PriceCalculator.vue"
  import TimesheetProcessing from "./TimesheetProcessing.vue"
  import OrderItem from "./OrderItem.vue"
  import ServiceItem from "./ServiceItem.vue"

  export default defineComponent({
    props: ["cpuValues", "ramValues", "diskValues", "projectLeads", "portfolioManagers", "publicHolidayZones", "model"],
    components: { VirtualDesktopAddon, PriceCalculator, TimesheetProcessing, OrderItem, ServiceItem },
    data() {
      return {
        showTimesheetProcessing: false,
        loading: false,
        finalModel: null as null | ClientCreateVMRequest,
      }
    },
    async mounted() {
      this.finalModel = JSON.parse(JSON.stringify(this.model))
      if (this.finalModel && this.finalModel.VirtualDesktopSpec) {
        this.finalModel.VirtualDesktopSpec.Cpu = Number(Object.values(this.cpuValues)[this.model.VirtualDesktopSpec.Cpu ?? 0])
        this.finalModel.VirtualDesktopSpec.Ram = Number(Object.values(this.ramValues)[this.model.VirtualDesktopSpec.Ram ?? 0])
        this.finalModel.VirtualDesktopSpec.Disk = Number(Object.values(this.diskValues)[this.model.VirtualDesktopSpec.Disk ?? 0])
      }
    },
    methods: {
      modifyVd() {
        this.$emit("cancel")
      },
      showTimesheetProcessingModal() {
        this.showTimesheetProcessing = true
      },
      closeTimesheetProcessingModal() {
        this.showTimesheetProcessing = false
      },
      saveTimesheetProcessing() {
        this.closeTimesheetProcessingModal()
        if (this.finalModel) {
          this.finalModel.EnableTimesheetProcessing = true
        }
      },
      async createVm() {
        if (this.finalModel) {
          this.loading = true
          try {
            await CommitmentService.createVmAndCommitment(this.finalModel)
            store.dispatch("showSuccessMessage", "VM request sent successfully")
            this.$router.push({ name: "management" })
          } finally {
            this.loading = false
          }
        }
      },
    },
    computed: {},
  })
</script>
<style scoped lang="scss">
  .setup-complete-container {
    padding: 24px;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .setup-complete-btn {
      width: 100%;
    }
  }
</style>
