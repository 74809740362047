import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/images/default-avatar.avif'


const _hoisted_1 = {
  key: 0,
  class: "loader"
}
const _hoisted_2 = {
  key: 1,
  class: "view-conitaner"
}
const _hoisted_3 = { class: "action-bar" }
const _hoisted_4 = {
  class: "main-template-container",
  id: "element-to-convert"
}
const _hoisted_5 = { class: "left-section" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  src: _imports_0,
  class: "employee-image"
}
const _hoisted_8 = { class: "employee-details about" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 1,
  class: "mt-4"
}
const _hoisted_11 = {
  key: 2,
  class: "mt-4"
}
const _hoisted_12 = { class: "mt-4 description" }
const _hoisted_13 = { class: "right-section" }
const _hoisted_14 = { class: "employee-name" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "employee-title" }
const _hoisted_17 = { class: "employee-details skills" }
const _hoisted_18 = { class: "employee-details experiences" }
const _hoisted_19 = { class: "employee-details personality" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.employee)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_v_progress_circular, {
            color: "red",
            indeterminate: ""
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.employee)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_btn, { onClick: _ctx.addToMyTeam }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Add to my team ")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_v_btn, null, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Potential ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_btn, null, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Vetting ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_btn, null, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Contact (HR) ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_btn, { onClick: _ctx.download }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" Detailed CV ")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_v_btn, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
              icon: "mdi-close"
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_ctx.employee.PhotoUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.employee.PhotoUrl,
                    class: "employee-image"
                  }, null, 8, _hoisted_6))
                : (_openBlock(), _createElementBlock("img", _hoisted_7)),
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "title-bar mt-4" }, "About", -1)),
              _createElementVNode("div", _hoisted_8, [
                (_ctx.employee.CodeClanCode)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, " RefNo: " + _toDisplayString(_ctx.employee.CodeClanCode), 1))
                  : _createCommentVNode("", true),
                (_ctx.employee.Educations && _ctx.employee.Educations.length > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, " Education: "))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getEducations(_ctx.employee.Educations), (item, index) => {
                  return (_openBlock(), _createElementBlock("span", {
                    class: "mt-2",
                    key: index
                  }, _toDisplayString(item), 1))
                }), 128)),
                (_ctx.employee.BirthDate)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, "Age: " + _toDisplayString(_ctx.getAge(_ctx.employee.BirthDate)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.employee.Description), 1)
              ]),
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "logo" }, null, -1))
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createTextVNode(_toDisplayString(_ctx.capitalize(_ctx.employee.FirstName)) + " " + _toDisplayString(_ctx.capitalize(_ctx.employee.LastName)) + " ", 1),
                _createVNode(_component_v_dialog, {
                  modelValue: _ctx.introductionDialogVisible,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.introductionDialogVisible) = $event)),
                  "max-width": "800"
                }, {
                  activator: _withCtx(({ props: activatorProps }) => [
                    _withDirectives((_openBlock(), _createBlock(_component_v_btn, _mergeProps({
                      variant: "text",
                      class: "mb-8"
                    }, activatorProps, { icon: "mdi-auto-fix" }), {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon)
                      ]),
                      _: 2
                    }, 1040)), [
                      [_directive_tooltip, 'Introduce ' + _ctx.capitalize(_ctx.employee.FirstName) + ' ' + _ctx.capitalize(_ctx.employee.LastName)]
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      loading: _ctx.tmRAGloading,
                      title: 'About ' + _ctx.capitalize(_ctx.employee.FirstName) + ' ' + _ctx.capitalize(_ctx.employee.LastName)
                    }, {
                      default: _withCtx(() => [
                        (_ctx.introductionDialogVisible)
                          ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", { innerHTML: _ctx.introduction }, null, 8, _hoisted_15)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_card_actions, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_spacer),
                            _createVNode(_component_v_btn, {
                              text: "Close Dialog",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.introductionDialogVisible = false))
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["loading", "title"])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.getTitle(_ctx.employee.ProfessionalDetail?.JobTitle?.Id)), 1),
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "title-bar" }, "Skills", -1)),
              _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.getSkills(_ctx.employee.Skills)), 1),
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "title-bar" }, "Experiences", -1)),
              _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.employee.CareerHistoryDescription), 1),
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "title-bar" }, "Personality", -1)),
              _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.employee.ProfessionalDetail?.Personality ?? "-"), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}