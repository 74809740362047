import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "close-btn" }
const _hoisted_2 = {
  key: 1,
  class: "ma-2 text-center text-h6 text-white"
}
const _hoisted_3 = { class: "inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_EmployeeItem = _resolveComponent("EmployeeItem")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_Search = _resolveComponent("Search")!
  const _component_InviteTeammember = _resolveComponent("InviteTeammember")!
  const _component_SearchByJD = _resolveComponent("SearchByJD")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_Details = _resolveComponent("Details")!
  const _component_Location = _resolveComponent("Location")!
  const _component_AddOns = _resolveComponent("AddOns")!
  const _component_v_stepper = _resolveComponent("v-stepper")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_CVTemplate1 = _resolveComponent("CVTemplate1")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showSearchDialog,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showSearchDialog) = $event)),
      persistent: "",
      "max-width": "1000px",
      loading: _ctx.dialogLoading,
      disabled: _ctx.dialogLoading,
      scrollable: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_v_btn, {
            icon: "mdi-close",
            variant: "tonal",
            onClick: _ctx.closeDialog
          }, null, 8, ["onClick"])
        ]),
        _createVNode(_component_v_stepper, {
          "hide-actions": "",
          "alt-labels": "",
          items: _ctx.commitmentSteps,
          ref: "stepper",
          modelValue: _ctx.stepState,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.stepState) = $event))
        }, {
          "item.1": _withCtx(() => [
            _createVNode(_component_v_card, {
              flat: "",
              class: "search-background"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_tabs, {
                  modelValue: _ctx.teammemberTabs,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.teammemberTabs) = $event)),
                  "bg-color": "primary"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_tab, { value: "CandidatesShortlisted" }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("Candidate Shortlist")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_tab, { value: "search" }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Search Database (AI)")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_tab, { value: "invite" }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("Someone You Know")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_tab, { value: "jobDescription" }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("Delegate to CODE CLAN")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_tabs_window, {
                      modelValue: _ctx.teammemberTabs,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.teammemberTabs) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_tabs_window_item, { value: "CandidatesShortlisted" }, {
                          default: _withCtx(() => [
                            (_ctx.isCandidatesLoading)
                              ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                                  key: 0,
                                  width: 3,
                                  size: 70,
                                  color: "red",
                                  indeterminate: "",
                                  class: "ma-auto d-flex justify-center"
                                }))
                              : (_ctx.listOfCandidatesShortlisted.length == 0)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_2, "No Data"))
                                : (_openBlock(), _createBlock(_component_v_row, { key: 2 }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listOfCandidatesShortlisted, (item, index) => {
                                        return (_openBlock(), _createBlock(_component_v_col, {
                                          cols: "12",
                                          sm: "12",
                                          md: "6",
                                          lg: "4",
                                          key: index
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_EmployeeItem, {
                                              employee: item.TeammemberInfo,
                                              skills: _ctx.skills,
                                              details: _ctx.details,
                                              showInScene: false,
                                              onViewTmDetails: _ctx.viewTmDetails
                                            }, null, 8, ["employee", "skills", "details", "onViewTmDetails"])
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }))
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_tabs_window_item, { value: "search" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Search, {
                              onViewTmDetails: _ctx.viewTmDetails,
                              positionSkills: _ctx.getPositionSkills,
                              positionRole: _ctx.getPositionJobRole,
                              positionJD: _ctx.getPostionJD,
                              showInScene: true
                            }, null, 8, ["onViewTmDetails", "positionSkills", "positionRole", "positionJD"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_tabs_window_item, { value: "invite" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_InviteTeammember, { onInviteTM: _ctx.inviteTeammember }, null, 8, ["onInviteTM"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_tabs_window_item, { value: "jobDescription" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_SearchByJD, {
                              positionJD: _ctx.getPostionJD,
                              onContinueJD: _ctx.ContinueJD
                            }, null, 8, ["positionJD", "onContinueJD"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          "item.2": _withCtx(() => [
            _createVNode(_component_Details, {
              selectedTM: _ctx.selectedTM,
              selectedProject: _ctx.project,
              projectLeads: _ctx.projectLeads,
              publicHolidayZones: _ctx.publicHolidayZones,
              projects: _ctx.projects,
              onBackStep: _ctx.backStep,
              portfolioManagers: _ctx.portfolioManagers,
              onNextStep: _ctx.updateCommitmentDetails,
              isJdSearch: _ctx.isJdSearch
            }, null, 8, ["selectedTM", "selectedProject", "projectLeads", "publicHolidayZones", "projects", "onBackStep", "portfolioManagers", "onNextStep", "isJdSearch"])
          ]),
          "item.3": _withCtx(() => [
            _createVNode(_component_Location, {
              onBackStep: _ctx.backStep,
              onNextStep: _ctx.updateLocationTypeDetails,
              onSaveLocation: _ctx.saveOfficeLocation,
              officeLocations: _ctx.officeLocations,
              isJdSearch: _ctx.isJdSearch
            }, null, 8, ["onBackStep", "onNextStep", "onSaveLocation", "officeLocations", "isJdSearch"])
          ]),
          "item.4": _withCtx(() => [
            _createVNode(_component_AddOns, {
              onBackStep: _ctx.backStep,
              onNextStep: _ctx.saveCommitment,
              lineManagers: _ctx.lineManagers,
              loading: _ctx.loading,
              officeSpaces: _ctx.officeSpaces,
              commitmentModel: _ctx.model
            }, null, 8, ["onBackStep", "onNextStep", "lineManagers", "loading", "officeSpaces", "commitmentModel"])
          ]),
          _: 1
        }, 8, ["items", "modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.confirmDialog,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.confirmDialog) = $event)),
          "max-width": "600"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode("Are you sure you want to close the Assign Team Member?")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "action-btns"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              class: "secondary_btn",
                              onClick: _ctx.noConfirm
                            }, {
                              default: _withCtx(() => _cache[13] || (_cache[13] = [
                                _createTextVNode("No")
                              ])),
                              _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_v_btn, {
                              class: "primary_btn",
                              onClick: _ctx.yesConfirm
                            }, {
                              default: _withCtx(() => _cache[14] || (_cache[14] = [
                                _createTextVNode("Yes")
                              ])),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue", "loading", "disabled"]),
    (_ctx.showTMDetails && _ctx.selectedTM)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 0,
          modelValue: _ctx.showTMDetails,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showTMDetails) = $event)),
          class: "custom-modal"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_CVTemplate1, {
                employeeId: _ctx.selectedTM.Id ?? '',
                skills: _ctx.skills,
                details: _ctx.details,
                onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop","prevent"])),
                onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showTMDetails = false)),
                onAddToMyTeam: _ctx.assignTmToPosition
              }, null, 8, ["employeeId", "skills", "details", "onAddToMyTeam"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}