import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, {
    class: _normalizeClass([_ctx.isTotal ? 'text-white pb-2' : ''])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "12",
        class: "total-cost-container"
      }, {
        default: _withCtx(() => [
          _createElementVNode("h4", null, "Estimated Monthly Cost AUD " + _toDisplayString(_ctx.getPerMonthPrice), 1),
          _createElementVNode("h4", null, "(AUD " + _toDisplayString(_ctx.getPerHourPrice) + " per work hour)", 1),
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "c-silver-2 f-body-1 hidden-sm-and-down" }, " Costs are estimated based on usage time. All smart workbench virtual desktops are dedicated 24/7, but you only get charged for the hours you use. ", -1)),
          (!_ctx.isTotal)
            ? (_openBlock(), _createBlock(_component_v_tooltip, {
                key: 0,
                text: _ctx.getPriceTooltip(),
                "max-width": "300"
              }, {
                activator: _withCtx(({ props }) => [
                  _createElementVNode("span", _mergeProps(props, { class: "f-body-1 hidden-sm-and-down" }), "More Information on transparent pricing", 16)
                ]),
                _: 1
              }, 8, ["text"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}