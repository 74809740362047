<template>
  <div>
    <v-form ref="mainForm">
      <v-card flat :loading="loading" :disabled="loading">
        <v-card-title>Add new Office Location</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <TextField label="Location Name" v-model="model.Name" :rules="[rules.required]" />
            </v-col>
            <v-col cols="12">
              <TextField label="Address" v-model="model.Address" :rules="[rules.required]" />
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <div class="actionBtns mt-3">
                <v-btn class="secondary_btn" @click="cancel" :loading="loading">Cancel</v-btn>
                <v-btn class="primary_btn" @click="save" :loading="loading">Save</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>
<script lang="ts">
  import CustomerService from "@/services/CustomerService"
  import { defineComponent } from "vue"

  export default defineComponent({
    props: [],
    data() {
      return {
        loading: false,
        model: {
          Address: "",
          Id: "",
          Name: "",
        },
        rules: {
          required: (value: any) => (!(value instanceof Array) && !!value) || (value instanceof Array && value.length > 0) || "Required.",
        },
      }
    },
    mounted() {},
    methods: {
      resetForm() {
        this.model = {
          Address: "",
          Id: "",
          Name: "",
        }
        ;(this.$refs.mainForm as any).reset()
      },
      cancel() {
        this.$emit("cancel")
      },
      async save() {
        const isValid = await (this.$refs.mainForm as any).validate()
        if (isValid.valid) {
          this.loading = true
          const response = await CustomerService.AddOfficeLocation(this.model)
          this.model.Id = response
          this.$emit("saveLocation", this.model)
          this.loading = false
          this.resetForm()
        }
      },
    },
  })
</script>
<style lang="scss" scoped>
  .actionBtns {
    display: flex;
    justify-content: space-between;
  }
</style>
