import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardTitle = _resolveComponent("CardTitle")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_NumberField = _resolveComponent("NumberField")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "b-eerie-black" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_CardTitle, { title: "Timesheet Processing" }),
          _createVNode(_component_v_form, {
            ref: "frmTimesheet",
            modelValue: _ctx.timesheetValid,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.timesheetValid) = $event)),
            class: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "align-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "6",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        rules: [_ctx.rules.required],
                        label: "Project Lead",
                        items: _ctx.projectLeads,
                        "item-title": "Name",
                        "item-value": "id",
                        modelValue: _ctx.model.CommitmentProjectLeadId,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.CommitmentProjectLeadId) = $event)),
                        "append-icon": "mdi-plus"
                      }, null, 8, ["rules", "items", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "6",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        rules: [!_ctx.isClient || _ctx.rules.required],
                        label: "Portfolio Manager",
                        items: _ctx.portfolioManagers,
                        "item-title": "Name",
                        "item-value": "id",
                        modelValue: _ctx.model.CommitmentPortfolioManagerId,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.CommitmentPortfolioManagerId) = $event)),
                        "append-icon": "mdi-plus",
                        disabled: !_ctx.isClient
                      }, null, 8, ["rules", "items", "modelValue", "disabled"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "6",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        rules: [_ctx.rules.required],
                        label: "Timezone",
                        modelValue: _ctx.model.CommitmentTimeZone,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.CommitmentTimeZone) = $event)),
                        items: _ctx.timeZones,
                        outlined: "",
                        "return-object": false
                      }, null, 8, ["rules", "modelValue", "items"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "6",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SelectField, {
                        rules: [_ctx.rules.required],
                        label: "Public Holiday Zone",
                        items: _ctx.publicHolidayZones,
                        "item-title": "Name",
                        "item-value": "id",
                        modelValue: _ctx.model.CommitmentPublicHolidayZoneId,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.CommitmentPublicHolidayZoneId) = $event))
                      }, null, 8, ["rules", "items", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "6",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        rules: [_ctx.rules.required],
                        label: "Days of Week",
                        modelValue: _ctx.model.CommitmentWorkingDays,
                        "onUpdate:modelValue": [
                          _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.CommitmentWorkingDays) = $event)),
                          _ctx.DayOfWeekChanged
                        ],
                        items: _ctx.workingDays,
                        multiple: "",
                        outlined: "",
                        "return-object": false
                      }, null, 8, ["rules", "modelValue", "items", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "4",
                    md: "2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        rules: [_ctx.rules.required],
                        label: "From",
                        modelValue: _ctx.model.CommitmentFrom,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.CommitmentFrom) = $event)),
                        items: _ctx.hoursInDay
                      }, null, 8, ["rules", "modelValue", "items"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "4",
                    md: "2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        rules: [_ctx.rules.required],
                        label: "To",
                        modelValue: _ctx.model.CommitmentTo,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.CommitmentTo) = $event)),
                        items: _ctx.hoursInDay
                      }, null, 8, ["rules", "modelValue", "items"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "6",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NumberField, {
                        disabled: _ctx.model.CommitmentHoursPerWeekRadio,
                        label: "Expected work hours per week",
                        rules: [_ctx.model.CommitmentHoursPerWeekRadio || _ctx.rules.minNumber(1) || _ctx.rules.required],
                        modelValue: _ctx.model.CommitmentHoursPerWeek,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.CommitmentHoursPerWeek) = $event)),
                        min: 1
                      }, null, 8, ["disabled", "rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "6",
                    md: "4",
                    class: "align-content-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_checkbox, {
                        label: "Casual",
                        "hide-details": "",
                        modelValue: _ctx.model.CommitmentHoursPerWeekRadio,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.CommitmentHoursPerWeekRadio) = $event)),
                        onChange: _ctx.CasualChange
                      }, null, 8, ["modelValue", "onChange"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        class: "d-flex align-end justify-center ga-3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            class: "secondary_btn",
                            onClick: _ctx.cancel
                          }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode("Cancel")
                            ])),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_v_btn, {
                            class: "primary_btn",
                            onClick: _ctx.save
                          }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createTextVNode("Add")
                            ])),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}