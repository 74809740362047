<template>
  <v-timeline density="compact" side="end" truncate-line="both">
    <v-timeline-item :dot-color="getTimelineColor(firstRelatedCommitment)" size="large" fill-dot>
      <template v-slot:icon v-if="commitment.RelatedActions?.length == 0">
        <v-icon>mdi-star</v-icon>
      </template>
      <v-card class="timeline-item pa-2">
        <v-row no-gutters>
          <v-col cols="12" class="text-center">
            {{ commitment?.Name }}
          </v-col>
          <v-col cols="12">
            Project Name:
            {{ truncate(commitment?.ProjectName) }}
          </v-col>
          <v-col cols="6">
            Start Date:
            {{ formatDate(firstRelatedCommitment?.StartDate) }}
          </v-col>
          <v-col cols="6">End Date: {{ formatDate(firstRelatedCommitment?.EndDate) }}</v-col>
          <v-col cols="12">Project Lead: {{ firstRelatedCommitment?.ProjectLeadName }}</v-col>
          <v-col cols="12">
            Portfolio Manager:
            {{ firstRelatedCommitment?.PortfolioManagerName }}
          </v-col>
          <v-col cols="12">
            Holiday Zone:
            {{ firstRelatedCommitment?.PublicHolidayZoneName }}
          </v-col>
          <v-col cols="12">
            Location Type:
            {{ commitment ? firstRelatedCommitment?.LocationType : "" }}
          </v-col>
        </v-row>
      </v-card>
    </v-timeline-item>

    <v-timeline-item v-for="(item, index) in commitment.RelatedActions" :key="index" :dot-color="getTimelineColor(item.ToCommitmentDetail)">
      <template v-slot:icon v-if="index == commitment.RelatedActions?.length - 1">
        <v-icon>mdi-star</v-icon>
      </template>
      <v-card class="timeline-item pa-2">
        <v-row no-gutters>
          <v-col cols="12">Requested at: {{ formatDate(item.CreatedAt) }}</v-col>
          <v-col cols="6">
            Start Date:
            {{ formatDate(item.ToCommitmentDetail?.StartDate) }}
          </v-col>
          <v-col cols="6">End Date: {{ formatDate(item.ToCommitmentDetail?.EndDate) }}</v-col>
          <v-col cols="12">
            Applied Actions:
            <br />
            <div class="pl-4">
              {{ item.DisplayName }}
              <v-icon size="small" icon="mdi-information" v-tooltip:top="getActionDetail(item)"></v-icon>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>
<script lang="ts">
  import { defineComponent, PropType } from "vue"
  import {
    ClientGetCommitmentByIdResponseModel,
    CommitmentActionItemTypeEnum,
    ClientGetCommitmentResponseRelatedActions,
    ClientGetCommitmentResponseCommitmentDetail,
    ClientGetCommitmentResponseCommitmentDetailStatusEnum,
  } from "shared-components/src/services/openApi/api"
  import ProjectSearchWizard from "../AddTeammember/ProjectSearchWizard.vue"
  import ActivityWatchReport from "shared-components/src/components/Reports/ActivityWatch/ActivityWatchReport.vue"
  import SecurityReport from "shared-components/src/components/Reports/Wazuh/Dashboard.vue"
  import VdSupport from "shared-components/src/components/Vm/VdSupport.vue"
  import CommitmentActionModal from "./CommitmentActionModal.vue"
  import Avatar from "@/components/Avatar.vue"
  import Utils from "shared-components/src/utils/Utils"
  import moment from "moment"

  export default defineComponent({
    components: {
      ProjectSearchWizard,
      ActivityWatchReport,
      VdSupport,
      SecurityReport,
      Avatar,
      CommitmentActionModal,
    },
    props: {
      commitment: {
        type: Object as PropType<ClientGetCommitmentByIdResponseModel>,
        required: true,
      },
    },
    data() {
      return {
        actionLists: CommitmentActionItemTypeEnum,
        commitmentStatus: ClientGetCommitmentResponseCommitmentDetailStatusEnum,
      }
    },
    async created() {},
    mounted() {},
    methods: {
      truncate(item: any) {
        if (item && item.length > 30) {
          return item.substring(0, 30) + "..."
        } else {
          return item
        }
      },
      formatDate(date: any) {
        date = new Date(date)
        if (date) {
          return Utils.toVsDateFormat(date)
        }
      },
      getActionDetail(action: ClientGetCommitmentResponseRelatedActions) {
        switch (action.Type) {
          case CommitmentActionItemTypeEnum.CustomerAddVirtualDesktop:
            return `Cpu: ${action.Details?.VirtualDesktop?.Cpu}, Memory: ${action.Details?.VirtualDesktop?.Ram}, Disk: ${action.Details?.VirtualDesktop?.Disk}`
          case CommitmentActionItemTypeEnum.CustomerModifyVirtualDesktop:
            return `Cpu: ${action.Details?.VirtualDesktop?.Cpu}, Memory: ${action.Details?.VirtualDesktop?.Ram}, Disk: ${action.Details?.VirtualDesktop?.Disk}`
          case CommitmentActionItemTypeEnum.CustomerExtendContract:
            return `Extend To Date: ${moment(action.Details?.ExtendContractDate).format("YYYY-MM-DD")}`
          case CommitmentActionItemTypeEnum.CustomerTerminateContract:
            return `Transfer Virtual Desktop: ${action.Details?.TerminateContract?.TransferVirtualDesktop}`
        }
      },
      getTimelineColor(commitment: ClientGetCommitmentResponseCommitmentDetail) {
        const startDate = moment(commitment.StartDate)
        const endDate = moment(commitment.EndDate)
        // Active Commitment
        if (commitment.Status == this.commitmentStatus.Active) {
          return "#0ecb7c" // it should be same as the c_emerald color in our design system
        }
        // Expired Commitment
        if (commitment.Status == this.commitmentStatus.Expired) {
          return "#848484" // it should be same as the c_battleship_grey1 color in our design system
        }
        // Upcoming Commitment
        if (
          commitment.Status == this.commitmentStatus.AdminPending ||
          commitment.Status == this.commitmentStatus.TeammemberPending ||
          commitment.Status == this.commitmentStatus.CustomerPending ||
          commitment.Status == this.commitmentStatus.Upcoming
        ) {
          return "#ffc107" // it should be same as the c_orange color in our design system
        }
      },
    },
    computed: {
      firstRelatedCommitment() {
        if (this.commitment.RelatedActions && this.commitment.RelatedActions.length > 0) {
          return this.commitment.RelatedActions.sort((a, b) => {
            const aDate = a.EffectiveDate ? new Date(a.EffectiveDate) : new Date()
            const bDate = b.EffectiveDate ? new Date(b.EffectiveDate) : new Date()
            return aDate > bDate ? 1 : aDate < bDate ? -1 : 0
          })[0].FromCommitmentDetail
        }
        return {
          EndDate: this.commitment.EndDate,
          LocationType: this.commitment.LocationType,
          PortfolioManagerName: this.commitment.PortfolioManagerName,
          ProjectLeadName: this.commitment.ProjectLeadName,
          PublicHolidayZoneName: this.commitment.PublicHolidayZoneName,
          StartDate: this.commitment.StartDate,
          Status: this.commitment.Status,
        } as ClientGetCommitmentResponseCommitmentDetail
      },
    },
  })
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/font.scss";
  @import "node_modules/shared-components/assets/style/style.scss";
  .timeline-item {
    @extend .b-r-10;
    @extend .b-white;
    @extend .c-black;
    @extend .f-body-3;
    font-weight: 600;
  }
</style>
