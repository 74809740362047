<template>
  <div>
    <v-row class="ga-2 justify-space-between">
      <v-col cols="12" sm="8" md="9">
        <v-row>
          <v-col cols="12" md="5" class="d-flex flex-column">
            <span class="f-sub-heading c-white">{{ orderModel.Title }}</span>
            <span class="f-body-2">{{ orderModel.Info1 }}</span>
            <span class="f-body-2">{{ orderModel.Info2 }}</span>
            <span class="f-body-2">{{ orderModel.Info3 }}</span>
          </v-col>
          <v-col cols="12" md="2" class="f-sub-heading c-white">QTY: 1</v-col>
          <v-col cols="12" md="5" class="d-flex flex-column ga-2">
            <span class="f-sub-heading c-white">Estimated Monthly Cost: AUD 42</span>
            <span class="f-sub-heading c-white">(AUD 0.21 per work hour)</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-btn class="tertiary_btn w-100" @click="modify()">Modify</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">More Information On Transparent Pricing</v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import PriceCalculator from "./PriceCalculator.vue"

  export default defineComponent({
    props: ["projectLeads", "publicHolidayZones", "model", "type"],
    components: { PriceCalculator },
    data() {
      return {}
    },
    async mounted() {},
    methods: {
      modify() {
        this.$emit("modify")
      },
      getProjectLeadName(id: string) {
        return this.projectLeads.find((item: any) => item.id == id).Name
      },
      getPublicHolidayZoneName(id: string) {
        return this.publicHolidayZones.find((item: any) => item.id == id).Name
      },
    },
    computed: {
      orderModel() {
        if (this.type == "VirtualDesktop") {
          return {
            Title: "Virtual Desktop",
            Info1: `Cpu: ${this.model.VirtualDesktopSpec?.Cpu} Core`,
            Info2: `Ram: ${this.model.VirtualDesktopSpec?.Ram} GB`,
            Info3: `Disk: ${this.model.VirtualDesktopSpec?.Disk} GB`,
          }
        }
        if (this.type == "TimesheetProcessing") {
          return {
            Title: "Timesheet Processing",
            Info1: `Project Lead: ${this.getProjectLeadName(this.model.CommitmentProjectLeadId)}`,
            Info2: `TimeZone: ${this.model.CommitmentTimeZone}`,
            Info3: `Public Holiday Zone: ${this.getPublicHolidayZoneName(this.model.CommitmentPublicHolidayZoneId)}`,
          }
        }
      },
    },
  })
</script>
<style scoped lang="scss"></style>
