<template>
  <div :loading="isLoading" class="magiclink-form pb-5 pt-5">
    <v-card-title class="justify-center" v-if="isLoading">
      <p>Checking the link...</p>
    </v-card-title>
    <div v-else>
      <v-card-title class="justify-center">
        <p>Welcome back {{ fullName }}</p>
      </v-card-title>
      <v-card-text>
        <v-layout wrap>
          <v-flex xs12>
            <v-checkbox v-model="staySignedIn" label="Keep me signed in"></v-checkbox>
          </v-flex>
        </v-layout>
      </v-card-text>
      <div class="actions">
        <v-btn @click="gotoDashboard()" class="fullSize-button primary_btn">Continue to my dashboard</v-btn>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import router from "@/router"
  import { useRoute } from "vue-router"
  import { defineComponent } from "vue"
  import { useStore } from "vuex"
  import UserService from "../../services/UserService"
  import { Paths } from "shared-components/src/definitions/constants"
  export default defineComponent({
    mounted() {
      this.isLoading = true
      localStorage.idToken = this.route.params.token
      const storeObject = useStore()
      UserService.verifyToken()
        .then((result) => {
          if (!result.email) {
            router.push(Paths.Client.Login)
          } else {
            if (localStorage.staySignedIn === "true" && localStorage.idToken) {
              this.gotoReturnedUrl()
            }

            this.fullName = result.fullName
            var clientInfo = result
            storeObject.dispatch("setUserInfo", { clientInfo, vm: this })
            this.isLoading = false
          }
        })
        .catch((error) => {
          router.push(Paths.Client.Login)
        })
    },
    data: () => ({
      fullName: "",
      staySignedIn: false,
      isLoading: false,
      route: useRoute(),
    }),
    methods: {
      gotoDashboard() {
        localStorage.staySignedIn = this.staySignedIn
        this.gotoReturnedUrl()
      },
      gotoReturnedUrl() {
        if (this.route.query && this.route.query.redirectUrl) {
          router.push(this.route.query.redirectUrl as string)
        } else {
          router.push(Paths.Client.Dashboard)
        }
      },
    },
  })
</script>
<style lang="scss" scoped>
  .magiclink-form {
    min-width: 330px;
  }
</style>
