<template>
  <div class="signin" :class="{ 'secondary-is-open': openSecondaryPanel }">
    <div class="common-container">
      <div id="signin-description" class="signin__card card--secondary" v-if="openSecondaryPanel">
        <img class="logo" src="@/assets/images/code-clan-logo.svg" />
        <div class="tempale-container">
          <component :is="template"></component>
        </div>
      </div>
      <v-card class="signin__card card--background signin-box">
        <v-card-title class="signin__header">
          <div class="signin__header w-100 pb-5">
            <div class="signin__title">
              <h4 class="headline" v-if="loginTitle">{{ loginTitle }}</h4>
              <div v-if="description">{{ description }}</div>
            </div>
            <img v-show="!openSecondaryPanel" class="logo" src="@/assets/images/code-clan-logo.svg" />
          </div>
        </v-card-title>
        <v-card-text>
          <div class="secondary-container"></div>
          <div>
            <router-view />
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
  import store from "@/store"
  import { defineComponent } from "vue"

  export default defineComponent({
    props: {},
    data() {
      return {
        openSecondaryPanel: false,
        description: "",
        template: null as boolean | null,
      }
    },
    created() {
      this.initPage()
    },
    computed: {
      loginTitle() {
        return store.state.loginEmailSent ? "On its way" : (this.$route.meta.title as string)
      },
    },
    methods: {
      initPage() {
        store.dispatch("setSendLoginStatus", false)
        this.description = this.$route.meta.description as string
        this.template = this.$route.meta.template as boolean
        this.openSecondaryPanel = this.template && this.template !== null
      },
    },
    watch: {
      $route() {
        this.initPage()
      },
    },
  })
</script>
<style scoped lang="scss">
  @import "node_modules/shared-components/assets/colors.scss";
  @media (max-width: 820px) {
    #signin-description {
      display: none;
    }
  }

  .signin {
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    .common-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .card--secondary {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 10px 20px;
        .tempale-container {
          padding: 20px;
        }
        .logo {
          height: auto;
          width: 150px;
        }
      }
    }

    &__card {
      width: 24vw;
      min-width: 330px;
      border-radius: 0;
    }
    &__header {
      background-color: $racecar !important;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        height: auto;
        width: 80px;
        margin-left: 20px;
      }
    }
  }

  .forms {
    display: grid;
    align-items: center;
  }

  .signin-box {
    width: 30vw;
  }
</style>
